import { removeUrlQueryParams } from 'src/utils/router';

const TIKTOK_REDIRECT_QUERY_PARAMS = ['auth_code', 'code', 'state'];

export const onInit = () => {
    const url = new URL(window.location.href);

    const isRedirectedFromTiktok = TIKTOK_REDIRECT_QUERY_PARAMS.every(param =>
        url.searchParams.has(param)
    );

    if (isRedirectedFromTiktok) {
        const sanitizedUrl = removeUrlQueryParams(window.location.href, [
            'code',
        ]);
        window.history.replaceState(null, '', sanitizedUrl);
    }
};
