import { PAGINATION_SEARCH } from 'src/hooks/useUrlParamsPagination';
import { initRoutes } from 'src/utils/router';

export const ROUTES = initRoutes('/audiences', {
    AUDIENCES: {
        path: '/',
        search: {
            ...PAGINATION_SEARCH,
            search: String,
            artist: String,
        },
    },
    AUDIENCES_CREATION: {
        path: '/create',
    },
    AUDIENCE: {
        path: '/:audienceId',
    },
});
