import { RESOURCE_TYPE_ACTIONS } from './constants';
import { checkPermission } from './utils/checkPermission';
import { getTenantRoles } from './utils/getTenantRoles';
import type { Permission, PermissionsPluginData } from './types';
import type {
    GraphqlClient,
    OnAppDataLoadCallback,
} from '@theorchard/suite-frontend';

export const getPermissions = (async ({
    identity,
    graphqlClient,
}: {
    identity: { id: string };
    graphqlClient: GraphqlClient;
}): Promise<PermissionsPluginData> => {
    const tenantRoles = await getTenantRoles({
        identityId: identity.id,
        graphqlClient,
    });

    // transform RESOURCE_TYPE_ACTIONS into an array, which is expected to be fetched from the permissions query.
    const permissions = RESOURCE_TYPE_ACTIONS.map(
        (resourceTypeAction): Permission => {
            const hasPermission = tenantRoles.filter(({ role }) =>
                resourceTypeAction.roles.includes(role)
            );

            return {
                resourceType: resourceTypeAction.resourceType,
                action: resourceTypeAction.action,
                effect: hasPermission.length ? 'allow' : 'deny',
                allowedForTenants: hasPermission.map(permission => ({
                    tenantType:
                        permission.tenant.__typename?.toLocaleLowerCase() ??
                        'unknown',
                    tenantUUID: permission.tenant.uuid,
                })),
            };
        }
    );

    return {
        tenantRoles,
        permissions,
        hasPermission: checkPermission(permissions),
    };
}) satisfies OnAppDataLoadCallback<PermissionsPluginData>;
